import { SoundControlButton } from '@canalplus/oneplayer-shared-components';
import { useCallback, type JSX } from 'react';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { setAreAnimationsTemporarilyUnmuted } from '../../../../store/slices/user';
import { useVideoState } from '../../context/hooks/useVideoState';

/**
 * This component will check if the playerInstance.player is defined to avoid null error exception on SoundControlButton
 * when player was destroyed
 */
export function SoundControlButtonWrapper(): JSX.Element | null {
  const appDispatch = useAppDispatch();
  const { playerInstance } = useVideoState();

  const handleOnMute = useCallback(() => {
    appDispatch(setAreAnimationsTemporarilyUnmuted(false));
  }, [appDispatch]);

  const handleOnUnMute = useCallback(() => {
    appDispatch(setAreAnimationsTemporarilyUnmuted(true));
  }, [appDispatch]);

  if (!playerInstance?.player) {
    return null;
  }

  return (
    <SoundControlButton
      onMute={handleOnMute}
      onUnmute={handleOnUnMute}
      variant="video"
    />
  );
}
