import classNames from 'classnames';
import type { JSX } from 'react';
import { Children } from 'react';
import { useIsVideoReadyToPlay } from '../../context/hooks/useIsVideoReadyToPlay';
import styles from './Actions.css';

type ActionsProps = {
  children: React.ReactNode;
};

function Actions({ children }: ActionsProps): JSX.Element {
  const isVideoReadyToPlay = useIsVideoReadyToPlay();

  return (
    <ul
      className={classNames(styles.actions, {
        [styles['actions--fadeIn']!]: isVideoReadyToPlay,
      })}
      aria-hidden={!isVideoReadyToPlay}
    >
      {Children.map(children, (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={styles.item}>
          {child}
        </li>
      ))}
    </ul>
  );
}

export default Actions;
